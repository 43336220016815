/* global FormData, location */

import TokenStore from './token-store';
import isProduction from 'lib/is-production';

export default class FileUpload {
  static async perform(file) {
    if (!file) {
      throw new Error('Missing required argument: file');
    }

    const fd = new FormData();
    fd.append('data[attributes][file]', file);

    const headers = {};

    // Add headers only in development
    if (!isProduction) {
      headers['X-API-Token'] = `Bearer ${TokenStore.apiToken}`;
    }

    const response = await fetch('/api/v2/file_uploads', {
      method: 'POST',
      body: fd,
      headers, // Use the headers object
    });

    if (response.ok) {
      const json = await response.json();
      const url = json.data.attributes.url;

      if (process.env.NODE_ENV === 'production') {
        return url;
      }

      return location.origin + url;
    }

    throw response;
  }
}
